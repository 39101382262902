import { Controller } from '@hotwired/stimulus'
import axios from 'axios'
import lzstring from 'lz-string'
import * as Sentry from '@sentry/browser'

export default class extends Controller {
  static targets = ['titleInput', 'urlDisplay']
  static values = { titleMaxLength: Number }

  connect() {
    this.debounceTimer = null
    this.generateUrl()

    // ブックマーク変更イベントのリスナーを追加
    this.boundHandleBookmarkChange = this.handleBookmarkChange.bind(this)
    document.addEventListener(
      'bookmark-changed',
      this.boundHandleBookmarkChange,
    )
  }

  disconnect() {
    // コントローラーが切断されるときにイベントリスナーを削除
    document.removeEventListener(
      'bookmark-changed',
      this.boundHandleBookmarkChange,
    )
  }

  updateUrl() {
    clearTimeout(this.debounceTimer)
    this.debounceTimer = setTimeout(() => {
      const title = this.titleInputTarget.value.trim()
      this.generateUrl(title)
    }, 400) // デバウンスmsec
  }

  async generateUrl(title = '') {
    try {
      const response = await axios.post('/api/web/bookmark_urlize', {})
      let url = response.data.url
      if (title) {
        const separator = url.includes('?') ? '&' : '?'
        url += `${separator}title=${this.compressForUrl(
          title,
          this.titleMaxLengthValue,
        )}`
      }
      this.urlDisplayTarget.href = url
      this.urlDisplayTarget.textContent = url
    } catch (error) {
      Sentry.captureException(error)
      alert('ブックマークURLの生成中にエラーが発生しました。')
    }
  }

  // ブックマーク変更イベントのハンドラー
  handleBookmarkChange() {
    const title = this.titleInputTarget.value.trim()
    this.generateUrl(title)
  }

  // 文字列を、標準のURIエンコード化よりは短くすべく、可逆圧縮して返す
  compressForUrl(str, maxLength) {
    // 最大文字数に制限
    const truncatedStr = str.slice(0, maxLength)
    // サロゲートペア範囲の文字（絵文字を含む）を削除
    const strWithoutSurrogates = truncatedStr
      .replace(/[\uD800-\uDFFF]/g, '')
      .trim()
    // LZ圧縮してBase64エンコード
    const compressed = lzstring.compressToBase64(strWithoutSurrogates)
    const url_safe = compressed
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '')
      .replace(/=/g, '.')

    return url_safe
  }
}
