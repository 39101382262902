import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['titleInput', 'urlDisplay', 'shareMessage']
  static values = {
    titlePrefix: String,
    fallbackMessage: String,
  }

  async share() {
    const url = this.urlDisplayTarget.href
    const title = `SUPPEのボードゲームシェアカタログ：${this.titleInputTarget.value}`
    const text = title + '\n' + url

    try {
      if (!navigator.share) {
        throw new Error('Web Share API not supported')
      }

      await navigator.share({
        /* title: title, */
        /* url: url, */
        text: text,
      })
    } catch (error) {
      // Web Share API未対応またはシェアがキャンセルされた場合
      if (error.message === 'Web Share API not supported') {
        alert(
          'ブラウザかデバイスがウェブシェア機能に対応していないため使えません。',
        )
      }
    }
  }
}
